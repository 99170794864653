* {
  margin: 0;
}
.inputs {
  background-color: lightgray;
  border: 0;
  outline: none;
  width: 300px;
  margin: 10px;
  height: 40px;
  border-radius: 5px;
  padding-left: 5px;
}
.inputarea {
  padding-left: 5px;
  background-color: lightgray;
  border: 0;
  margin: 10px;
  outline: none;
  width: 300px;
  border-radius: 5px;
}
.submit {
  border: 0;
  margin: 10px;
  outline: none;
  width: 300px;
  border-radius: 5px;
  outline: none;
  background-color: #6d4c41;
  color: white;
  height: 40px;
}
.top {
  border: 0;
  outline: none;
  width: 100%;
  height: 40px;
  background-color: #6d4c41;
  color: white;
}
.footlinks,
.footlinksc,
.footlinksc:hover {
  color: #979a9a;
}
.footlinks:hover {
  color: #6d4c41;
}
label {
  height: 40px;
  background-color: #6d4c41;
  color: white;
  padding: 0.5rem;
  margin-left: 10px;
  border-radius: 0.3rem;
  cursor: pointer;
}
#file-chosen {
  margin-left: 0.3rem;
  font-family: sans-serif;
}

.careers {
  height: 550px;
}

@media only screen and (min-width: 800px) {
  .careers {
    height: 650px;
    background-image: url(./Assets/career.png);
    background-repeat: no-repeat;
  }
}
@media screen and (max-width: 500px) {
  .logoname {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 365px) {
  .logoname {
    font-size: 10px !important;
  }
}
.hvrlogos:hover {
  height: 76px;
  margin-bottom: 9px !important;
  margin-top: -1px !important;
}
.parallax {
  /* The image used */
  background-image: url("./Assets/parallax.jpg");

  /* Set a specific height */
  min-height: 400px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 1050px) {
  #valuesimg {
    height: 150px;
    width: 450px;
  }
}
@media screen and (max-width: 550px) {
  #valuesimg {
    height: 100px;
    width: 300px;
  }
}
@media screen and (max-width: 400px) {
  #valuesimg {
    height: 80px;
    width: 240px;
  }
}
.loader {
  width: 750px;
  height: 500px;
}
@media screen and (max-width: 1050px) {
  .loader {
    width: 600px !important;
    height: 400px !important;
  }
  .paratext {
    width: 70% !important;
  }
  .parallax {
    padding-left: 30px !important;
  }
  .prds {
    flex-wrap: wrap !important;
  }
  .features {
    display: none;
  }
}
@media screen and (max-width: 750px) {
  .loader {
    width: 550px !important;
    height: 366px !important;
  }
  .paratext {
    width: 60% !important;
  }
  .parallax {
    padding-left: 25px !important;
  }
  .prds {
    flex-wrap: wrap !important;
    width: 90vw !important;
  }
  .prdsname {
    width: 80vw !important;
  }
  .features {
    display: none;
  }
  .cc {
    margin-left: 20%;
  }
}
@media screen and (max-width: 610px) {
  .loader {
    width: 500px !important;
    height: 333px !important;
  }
  .paratext {
    width: 50% !important;
  }
  .parallax {
    padding-left: 30px !important;
  }
  .prds {
    flex-wrap: wrap !important;
  }
  .features {
    display: none;
  }
  .cc {
    margin-left: 20%;
  }
}
@media screen and (max-width: 550px) {
  .loader {
    width: 500px !important;
    height: 333px !important;
    margin-left: -30px;
    margin-top: 0%;
  }
  .paratext {
    width: 50% !important;
  }
  .parallax {
    padding-left: 20px !important;
  }
  .prds {
    flex-wrap: wrap !important;
  }
  .features {
    display: none;
  }
  .cc {
    margin-left: 20%;
  }
}
@media screen and (max-width: 450px) {
  .loader {
    width: 450px !important;
    height: 300px !important;
    margin-left: -26px;
    margin-top: 10%;
  }
  .paratext {
    width: 50% !important;
  }
  .parallax {
    padding-left: 20px !important;
  }
  .prds {
    flex-wrap: wrap !important;
  }
  .features {
    display: none;
  }
  .cc {
    margin-left: 20%;
  }
}
@media screen and (max-width: 400px) {
  .loader {
    width: 400px !important;
    height: 267px !important;
    margin-left: -20px;
    margin-top: 45%;
  }
  .paratext {
    width: 100% !important;
  }
  .parallax {
    padding-left: 10px !important;
  }
  .prds {
    flex-wrap: wrap !important;
  }
  .features {
    display: none;
  }
}
@media screen and (max-width: 350px) {
  .loader {
    width: 400px !important;
    height: 267px !important;
    margin-left: -10px;
    margin-top: 35%;
  }
  .paratext {
    width: 100% !important;
  }
  .parallax {
    padding-left: 10px !important;
  }
  .prds {
    flex-wrap: wrap !important;
  }
  .features {
    display: none;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: whitesmoke;
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  /* border-radius: 10px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(109, 107, 107);
}
