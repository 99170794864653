#mySidenav a {
  position: relative;
  left: -80px;
  transition: 0.2s;
  padding: 10px;
  padding-left: 90px;
  width: 300px;
  height: 50px;
  text-decoration: none;
  font-size: 20px;
  color: white;
  border-radius: 0 5px 5px 0;
}

#mySidenav a:hover {
  left: 0;
}

.activenav {
  left: 0 !important;
}

#auto {
  top: 20px;
  padding-left: 135px !important;
  background-color: #474747;
}

#foot {
  top: 30px;
  padding-left: 140px !important;
  background-color: #6d4c41;
}

#furnish {
  top: 40px;
  padding-left: 138px !important;
  background-color: #474747;
}

#sport {
  top: 50px;
  padding-left: 115px !important;
  background-color: #6d4c41;
}
#leather {
  top: 60px;
  padding-left: 115px !important;
  background-color: #474747;
}
.imagedivpro {
  /* background-size: 100% 100%;
  background-position: fixed; */
  /* height: auto; */
  overflow-x: hidden;
  background-position: center;
  height: auto;
  background-size: auto;
}
#overlay-divpro {
  font-size: 50px;
}
.roundbtn {
  z-index: -1;
  position: relative;
  background-color: #6d4c41;
  border-radius: 15px 15px;
  outline: none;
  border: 0;
  color: white;
  width: 60px;
  height: 50px;
  top: 10px;
  /* padding-right: 15px;
  padding-bottom: 8px; */
  display: none;
}
@media screen and (max-width: 1100px) {
  .imagedivpro {
    /* background-image: url("../Assets/overview.jpeg"); */
    height: auto;
    background-size: auto;
  }
  #textpro {
    font-size: 35px;
  }
  #textpro > hr {
    height: 3px;
  }
  .sidenav a {
    display: none;
  }
  .roundbtn {
    display: block;
  }
}
#textpro {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: white;
  float: right;
  position: absolute;
  top: 550px;
  right: 1%;
}
#textpro > hr {
  opacity: 1;
  margin-top: -10px;
  border-radius: 5px;
  height: 6px;
  background-color: #6d4c41;
}
@media screen and (max-width: 1250px) {
  #textpro {
    top: 500px;
  }
  #textpro > hr {
    margin-top: -8px;
    height: 5px;
  }
}
@media screen and (max-width: 1050px) {
  #textpro {
    top: 400px;
  }
  #textpro > hr {
    margin-top: -6px;
    height: 4px;
  }
  .dots {
    margin-left: 0px !important;
  }
}
@media screen and (max-width: 610px) {
  #textpro {
    top: 280px;
    font-size: 30px;
  }
  #textpro > hr {
    margin-top: -5px;
    height: 3px;
  }
}
@media screen and (max-width: 550px) {
  #textpro {
    top: 200px;
    font-size: 30px;
  }
  #textpro > hr {
    margin-top: -4px;
    height: 3px;
  }
}
@media screen and (max-width: 430px) {
  #textpro {
    top: 170px;
    font-size: 28px;
  }
  #textpro > hr {
    margin-top: 0px;
    height: 2px;
  }
}
@media screen and (max-width: 400px) {
  #textpro {
    top: 160px;
    font-size: 25px;
  }
  #textpro > hr {
    margin-top: 0px;
    height: 2px;
  }
  .dots {
    margin-left: 0px !important;
  }
}
@media screen and (max-width: 340px) {
  #textpro {
    top: 140px;
    font-size: 25px;
  }
  #textpro > hr {
    margin-top: 0px;
    height: 2px;
  }
  .dots {
    margin-left: 0px !important;
  }
}
