#mySidenav a {
  position: relative;
  left: -80px;
  transition: 0.2s;
  padding: 10px;
  padding-left: 90px;
  width: 300px;
  height: 50px;
  text-decoration: none;
  font-size: 20px;
  color: white;
  border-radius: 0 5px 5px 0;
}

#mySidenav a:hover {
  left: 0;
}

.activenav {
  left: 0 !important;
}

#overview {
  top: 20px;
  padding-left: 145px !important;
  background-color: #474747;
}

#board {
  top: 30px;
  padding-left: 112px !important;
  background-color: #6d4c41;
}

#vision {
  top: 40px;
  padding-left: 110px !important;
  background-color: #474747;
}

#industrial {
  top: 50px;
  background-color: #6d4c41;
}
.imagediv {
  overflow-x: hidden;
  background-position: center;
  height: auto;
  background-size: auto;
}
#overlay-div {
  font-size: 50px;
}
.roundbtn {
  z-index: -1;
  position: relative;
  background-color: #6d4c41;
  border-radius: 15px 15px;
  outline: none;
  border: 0;
  color: white;
  width: 60px;
  height: 50px;
  top: 10px;
  /* padding-right: 15px;
  padding-bottom: 8px; */
  display: none;
}
@media screen and (max-width: 1100px) {
  .imagediv {
    /* background-image: url("../Assets/overview.jpeg"); */
    height: auto;
    background-size: auto;
  }
  #text {
    font-size: 35px;
  }
  #text > hr {
    height: 3px;
  }
  .sidenav a {
    display: none;
  }
  .roundbtn {
    display: block;
  }
}
#text {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: white;
  float: right;
  position: absolute;
  top: 550px;
  right: 1%;
}
#text > hr {
  opacity: 1;
  margin-top: -10px;
  border-radius: 5px;
  height: 6px;
  background-color: #6d4c41;
}
@media screen and (max-width: 1050px) {
  #text {
    top: 400px;
  }
  #text > hr {
    margin-top: -6px;
    height: 4px;
  }
}
@media screen and (max-width: 550px) {
  #text {
    top: 200px;
    font-size: 30px;
  }
  #text > hr {
    margin-top: -4px;
    height: 3px;
  }
}
@media screen and (max-width: 400px) {
  #text {
    top: 170px;
    font-size: 25px;
  }
  #text > hr {
    margin-top: 0px;
    height: 2px;
  }
}
